import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getAllDecisions, getAllImpressions } from 'redux/modules/experiments/selectors';
import { dataLayer } from 'analytics/data-layer';
import { MonetateImpressionReport } from 'redux/modules/experiments/actions/types';

let contentSquareReport!: (impressions: Record<string, MonetateImpressionReport>) => void;

const ExperimentsReporter = () => {
  const decisions: Record<string, string> = useSelector(getAllDecisions) || {};
  const impressions: Record<string, MonetateImpressionReport> =
    useSelector(getAllImpressions) || {};

  const formattedDecisions: { id: string; variant: string }[] = [];
  const formattedLabels: { [name: string]: string } = {};

  Object.entries(decisions).forEach(([key, value]) => {
    formattedDecisions.push({ id: `fe_${key}`, variant: value });
    formattedLabels[`monetateExperiment_${key}`] = value;
  });

  useEffect(() => {
    window.apm?.addLabels(formattedLabels);

    dataLayer.push({
      event: 'monetate_fe_experiment_page_load',
      monetate_fe_experiment_page_load: formattedDecisions,
    });

    async function contentSquareImpressionsReport() {
      if (!contentSquareReport) {
        contentSquareReport = (await import('./contentSquareReport')).default;
      }

      contentSquareReport(impressions);
    }

    contentSquareImpressionsReport();
  });

  return null;
};

export default ExperimentsReporter;
